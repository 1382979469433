<template>
	<svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path d="M16.0053 28C15.0293 28.0027 14.0507 27.4173 13.7667 26.244C13.6814 25.8923 13.5144 25.5656 13.2791 25.2907C13.0437 25.0157 12.7468 24.8002 12.4125 24.6617C12.0781 24.5232 11.7158 24.4655 11.355 24.4935C10.9941 24.5215 10.645 24.6343 10.336 24.8227C8.27867 26.076 5.92267 23.7213 7.176 21.6627C7.36414 21.3538 7.47673 21.0049 7.50465 20.6443C7.53256 20.2837 7.47499 19.9216 7.33663 19.5875C7.19827 19.2533 6.98301 18.9566 6.70836 18.7213C6.43371 18.486 6.10742 18.3188 5.756 18.2333C3.41467 17.6653 3.41467 14.3347 5.756 13.7667C6.10773 13.6814 6.43437 13.5144 6.70933 13.2791C6.98429 13.0437 7.19981 12.7468 7.33833 12.4125C7.47685 12.0781 7.53447 11.7158 7.50649 11.355C7.47852 10.9941 7.36574 10.645 7.17733 10.336C5.924 8.27867 8.27867 5.92267 10.3373 7.176C11.6707 7.98667 13.3987 7.26933 13.7667 5.756C14.3347 3.41467 17.6653 3.41467 18.2333 5.756C18.3186 6.10773 18.4856 6.43437 18.7209 6.70933C18.9563 6.98429 19.2532 7.19981 19.5875 7.33833C19.9219 7.47685 20.2842 7.53447 20.645 7.50649C21.0059 7.47852 21.355 7.36574 21.664 7.17733C23.7213 5.924 26.0773 8.27867 24.824 10.3373C24.6359 10.6462 24.5233 10.9951 24.4954 11.3557C24.4674 11.7163 24.525 12.0784 24.6634 12.4125C24.8017 12.7467 25.017 13.0434 25.2916 13.2787C25.5663 13.514 25.8926 13.6812 26.244 13.7667C27.9853 14.1893 28.4307 16.14 27.5827 17.3453M21.3333 29.3333L28 22.6667M28 22.6667V28.6667M28 22.6667H22M16 20C17.0609 20 18.0783 19.5786 18.8284 18.8284C19.5786 18.0783 20 17.0609 20 16C20 14.9391 19.5786 13.9217 18.8284 13.1716C18.0783 12.4214 17.0609 12 16 12C14.9391 12 13.9217 12.4214 13.1716 13.1716C12.4214 13.9217 12 14.9391 12 16C12 17.0609 12.4214 18.0783 13.1716 18.8284C13.9217 19.5786 14.9391 20 16 20Z" stroke="#A9A9AF" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
	</svg>
</template>

<script>
export default {
	name: 'CMSClicks',
};
</script>

<style scoped>

</style>
