<template>
	<svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path d="M22.6666 10.6667V6.66668C22.6666 6.31305 22.5262 5.97392 22.2761 5.72387C22.0261 5.47382 21.6869 5.33334 21.3333 5.33334H7.99998C7.29274 5.33334 6.61446 5.61429 6.11436 6.11439C5.61426 6.61449 5.33331 7.29277 5.33331 8.00001M5.33331 8.00001C5.33331 8.70725 5.61426 9.38553 6.11436 9.88563C6.61446 10.3857 7.29274 10.6667 7.99998 10.6667H24C24.3536 10.6667 24.6927 10.8072 24.9428 11.0572C25.1928 11.3072 25.3333 11.6464 25.3333 12V16M5.33331 8.00001V24C5.33331 24.7073 5.61426 25.3855 6.11436 25.8856C6.61446 26.3857 7.29274 26.6667 7.99998 26.6667H24C24.3536 26.6667 24.6927 26.5262 24.9428 26.2762C25.1928 26.0261 25.3333 25.687 25.3333 25.3333V21.3333M26.6666 16V21.3333H21.3333C20.6261 21.3333 19.9478 21.0524 19.4477 20.5523C18.9476 20.0522 18.6666 19.3739 18.6666 18.6667C18.6666 17.9594 18.9476 17.2812 19.4477 16.7811C19.9478 16.281 20.6261 16 21.3333 16H26.6666Z" stroke="#A9A9AF" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
	</svg>
</template>

<script>
export default {
	name: 'Budgets',
};
</script>

<style scoped></style>
