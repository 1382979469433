<template>
	<svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path d="M21.3334 12V9.33333M21.3334 9.33333H18.6667M21.3334 9.33333L17.3334 13.3333L13.7147 10.6667L10.6667 13.3333M10.6667 18H21.3334M10.6667 22.6667H16M7.70404 4H24.296C25.6054 4 26.6667 5.19333 26.6667 6.66667V25.3333C26.6667 26.8067 25.6054 28 24.296 28H7.70404C6.39471 28 5.33337 26.8067 5.33337 25.3333V6.66667C5.33337 5.19333 6.39471 4 7.70404 4Z" stroke="#A9A9AF" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
	</svg>
</template>

<script>
export default {
	name: 'AnalyticsTemplate',
};
</script>

<style scoped></style>
