<template>
	<svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path d="M24.6667 24.6667L28 28M5.33337 8H26.6667M5.33337 16H10.6667M5.33337 24H10.6667M14.6667 20C14.6667 21.4145 15.2286 22.771 16.2288 23.7712C17.229 24.7714 18.5856 25.3333 20 25.3333C21.4145 25.3333 22.7711 24.7714 23.7713 23.7712C24.7715 22.771 25.3334 21.4145 25.3334 20C25.3334 18.5855 24.7715 17.229 23.7713 16.2288C22.7711 15.2286 21.4145 14.6667 20 14.6667C18.5856 14.6667 17.229 15.2286 16.2288 16.2288C15.2286 17.229 14.6667 18.5855 14.6667 20Z" stroke="#A9A9AF" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
	</svg>
</template>

<script>
export default {
	name: 'KeywordsReport',
};
</script>

<style scoped></style>
