<template>
	<svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path d="M20.6363 6.86362V24.8635C20.6363 26.8776 22.0259 27.9999 23.5 27.9999C24.8636 27.9999 26.3636 27.0453 26.3636 24.8635V6.99999C26.3636 5.15363 25 4 23.5 4C22 4 20.6363 5.27227 20.6363 6.86362Z" stroke="#A9A9AF" stroke-width="1.5"/>
		<path d="M13.1364 16.0001V24.8637C13.1364 26.8777 14.5259 28 16 28C17.3636 28 18.8636 27.0455 18.8636 24.8637V16.1364C18.8636 14.2901 17.5 13.1364 16 13.1364C14.5 13.1364 13.1364 14.4087 13.1364 16.0001Z" stroke="#A9A9AF" stroke-width="1.5"/>
		<path d="M8.50004 27.9999C10.0816 27.9999 11.3637 26.7178 11.3637 25.1362C11.3637 23.5547 10.0816 22.2726 8.50004 22.2726C6.9185 22.2726 5.63641 23.5547 5.63641 25.1362C5.63641 26.7178 6.9185 27.9999 8.50004 27.9999Z" stroke="#A9A9AF" stroke-width="1.5"/>
	</svg>
</template>

<script>
export default {
	name: 'Ga',
};
</script>

<style scoped></style>
