<template>
	<svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path d="M5.33331 10.6666H26.6666M10.6666 5.33331V10.6666M18 20.6666L20 22.6666M5.33331 6.66665C5.33331 6.31302 5.47379 5.97389 5.72384 5.72384C5.97389 5.47379 6.31302 5.33331 6.66665 5.33331H25.3333C25.6869 5.33331 26.0261 5.47379 26.2761 5.72384C26.5262 5.97389 26.6666 6.31302 26.6666 6.66665V25.3333C26.6666 25.6869 26.5262 26.0261 26.2761 26.2761C26.0261 26.5262 25.6869 26.6666 25.3333 26.6666H6.66665C6.31302 26.6666 5.97389 26.5262 5.72384 26.2761C5.47379 26.0261 5.33331 25.6869 5.33331 25.3333V6.66665ZM12 17.8666C12 18.7153 12.3371 19.5293 12.9372 20.1294C13.5374 20.7295 14.3513 21.0666 15.2 21.0666C16.0487 21.0666 16.8626 20.7295 17.4627 20.1294C18.0628 19.5293 18.4 18.7153 18.4 17.8666C18.4 17.018 18.0628 16.204 17.4627 15.6039C16.8626 15.0038 16.0487 14.6666 15.2 14.6666C14.3513 14.6666 13.5374 15.0038 12.9372 15.6039C12.3371 16.204 12 17.018 12 17.8666Z" stroke="#A9A9AF" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
	</svg>
</template>

<script>
export default {
	name: 'PageDetailInfo',
};
</script>

<style scoped>

</style>
