<template>
	<svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path d="M12 25.3333V17.3333C12 16.9797 11.8595 16.6406 11.6095 16.3905C11.3594 16.1405 11.0203 16 10.6667 16H5.33333C4.97971 16 4.64057 16.1405 4.39052 16.3905C4.14048 16.6406 4 16.9797 4 17.3333V25.3333C4 25.687 4.14048 26.0261 4.39052 26.2762C4.64057 26.5262 4.97971 26.6667 5.33333 26.6667M12 25.3333C12 25.687 11.8595 26.0261 11.6095 26.2762C11.3594 26.5262 11.0203 26.6667 10.6667 26.6667H5.33333M12 25.3333C12 25.687 12.1405 26.0261 12.3905 26.2762C12.6406 26.5262 12.9797 26.6667 13.3333 26.6667H18.6667C19.0203 26.6667 19.3594 26.5262 19.6095 26.2762C19.8595 26.0261 20 25.687 20 25.3333M12 25.3333V12C12 11.6464 12.1405 11.3072 12.3905 11.0572C12.6406 10.8072 12.9797 10.6667 13.3333 10.6667H18.6667C19.0203 10.6667 19.3594 10.8072 19.6095 11.0572C19.8595 11.3072 20 11.6464 20 12V25.3333M5.33333 26.6667H24M20 25.3333C20 25.687 20.1405 26.0261 20.3905 26.2762C20.6406 26.5262 20.9797 26.6667 21.3333 26.6667H26.6667C27.0203 26.6667 27.3594 26.5262 27.6095 26.2762C27.8595 26.0261 28 25.687 28 25.3333V6.66668C28 6.31305 27.8595 5.97392 27.6095 5.72387C27.3594 5.47382 27.0203 5.33334 26.6667 5.33334H21.3333C20.9797 5.33334 20.6406 5.47382 20.3905 5.72387C20.1405 5.97392 20 6.31305 20 6.66668V25.3333Z" stroke="#A9A9AF" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
	</svg>
</template>

<script>
export default {
	name: 'Tier',
};
</script>

<style scoped></style>
